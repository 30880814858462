import React from 'react'
import RightArrow from '../../Icons/RightArrow'
import RightUpArrowIcon from '../../Icons/RightUpArrowIcon'
import { callNumber } from '../../App'

const HomeBanner = () => {
  return (
    <div id='home' className='min-h-[80vh] bannerbg'>
      <main className='row container min-h-[80vh] mx-auto h-full' >
        <section className='col-lg-7 sans  flex ' >
          <article className='my-auto ' >
            <h6 className='text-5xl sm:text-7xl ' >Building Better Homes <br />
              <span className='txtclr fw-semibold' >
                for a Better Living </span> </h6>
            <div className='flex items-center flex-wrap  gap-3 ' >

              <a href={`https://wa.me/${callNumber}`} target='_blank'
                className='bgclr flex items-center gap-2 border-2 brclr text-decoration-none text-white p-[12px] uppercase my-3 jakarta ' >
                <RightUpArrowIcon />  Message Us
              </a>
              <a href={`tel:${callNumber}`} target='_blank' className='flex text-decoration-none text-slate-800 items-center 
                gap-2 p-[12px] border-2 border-slate-700 uppercase my-3 jakarta ' >
                <RightUpArrowIcon />  Call Us
              </a>
            </div>
          </article>

        </section>

      </main>

    </div>
  )
}

export default HomeBanner