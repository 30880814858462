import React from 'react'
import CallIcon from '../Icons/CallIcon'
import MailIcon from '../Icons/MailIcon'
import ClockIcon from '../Icons/ClockIcon'
import FaceBookIcon from '../Icons/FaceBookIcon'
import YoutubeIcon from '../Icons/YoutubeIcon'
import InstagramIcon from '../Icons/InstagramIcon'
import { callNumber, mail } from '../App'

const MiniNavBar = () => {
    return (
        <div className='border-b-[1px] ' >
            <main className='d-none  d-sm-flex flex-wrap items-center p-3 justify-between px-4 ' >
                <section className='flex flex-wrap items-center gap-3 justify-between gap-sm-4 ' >
                    <div className='txtclr flex  items-center gap-2' >
                        <CallIcon />
                        <a href={`tel:${callNumber}`} target='_blank' className='text-sm m-0 text-slate-800 text-decoration-none ' >{callNumber} </a>
                    </div>
                    <div className='txtclr flex items-center gap-2' >
                        <MailIcon />
                        <a href={`mailto:${mail}`} target='_blank' className='text-sm m-0 text-slate-800 text-decoration-none ' > {mail} </a>
                    </div>
                    <div className='txtclr flex items-center gap-2' >
                        <ClockIcon />
                        <a href="telto:+919791582480" className='text-sm m-0 text-slate-800 text-decoration-none ' >Mon - Sat 9:00 AM - 7:00 PM </a>
                    </div>


                </section>
                <section className='flex gap-3 flex-wrap px-sm-4 items-center ' >
                    <a href="https://www.facebook.com/profile.php?id=61556596644719" target='_blank' className='text-black ' >
                        <FaceBookIcon />
                    </a>
                    <a href="https://www.instagram.com/msbuilders22?igsh=MWVqd3Z0eng3a3Y3OA==" target='_blank' className='text-black ' >
                        <InstagramIcon />
                    </a>
                    <a href="https://youtube.com/@msbuilders2k19?si=J5j_RhAkMn--38kV" target='_blank' className='text-black ' >
                        <YoutubeIcon />
                    </a>

                </section>
            </main>
        </div>
    )
}

export default MiniNavBar