import React, { useState } from 'react'

const ProjectsSection = () => {
    let [selectedIndex, setSelectedIndex] = useState(0)

    let project = [
        {
            name: 'Building Renovation',
            content: `A 50-year-old building has been changed into a fresh, modern space for comfortable living.`,
            location: 'Kulithalai',
            area: '2100 sq.ft',
            status: 'Completed'
        },
        {
            name: 'Roof Upgrade',
            content: `Roof durability is improved with chemical treatments and beautiful clay tile flooring for lasting comfort.`,
            location: 'Karur',
            area: '1500 sq.ft',
            status: 'Completed'
        },
        {
            name: 'Luxury House (4BHK)',
            content: `A stunning 4BHK home with bright lighting and a lovely balcony garden for family moments.`,
            location: 'Thogamalai',
            area: '2500 sq.ft',
            status: 'Upcoming'
        },
        {
            name: 'Compact House (1BHK)',
            content: `This cozy 1BHK house saves space while providing comfort and convenience for daily living.`,
            location: 'Ayyampalam',
            area: '2500 sq.ft',
            status: 'Upcoming'
        },
        {
            name: 'Luxury House (4BHK)',
            content: `A spacious 4BHK home with elegant lighting and a beautifully decorated balcony garden for family gatherings.`,
            location: 'Velanganatham',
            area: '1300 sq.ft',
            status: 'Upcoming'
        },
        {
            name: 'Compact House (1BHK)',
            content: `An efficient 1BHK house designed for comfort in a smartly arranged space for everyday living. `,
            location: 'Uraiyur',
            area: '570 sq.ft',
            status: 'Completed'
        },
        {
            name: 'Residental Building',
            content: `A two-floor building that gives families a spacious and comfortable place to live. `,
            location: 'Vegur',
            area: '2600 sq.ft',
            status: 'Completed'
        },
        {
            name: 'Luxury House (2BHK)',
            content: `A modern 2BHK house featuring a warm kitchen and an east-facing door for bright mornings.`,
            location: 'Lalapet',
            area: '900 sq.ft',
            status: 'Completed'
        },
        {
            name: 'Spacious House (2BHK)',
            content: `A 2BHK home with a large hall and bedrooms, perfect for families to grow together. `,
            location: 'Kulithalai',
            area: '800 sq.ft',
            status: 'Completed'
        },
        {
            name: 'Luxury House (3BHK)',
            content: `This stunning 3BHK pyramid-shaped house offers spacious living areas and bright bedrooms, perfect for family life. Enjoy 
            a unique architectural design that combines style and comfort for an exceptional living experience. `,
            location: 'Thottiyam',
            area: '1900 sq.ft',
            status: 'Ongoing'
        },
        {
            name: 'Compact House (2BHK)',
            content: `A practical 2BHK house that uses space well, offering cozy spots for family fun and relaxation. `,
            location: 'Kattuputhur',
            area: '800 sq.ft',
            status: 'Ongoing'
        },
        {
            name: 'Spacious House (3BHK)',
            content: `A 3BHK house with a big hall and bedrooms, creating a loving atmosphere for families.`,
            location: 'Myladi',
            area: '2000 sq.ft',
            status: 'Ongoing'
        },

    ]
    let [selectedContent, setSelectedContent] = useState(project[0])
    return (
        <div className='py-sm-5  ' >
            <main className='container rounded-2xl mx-auto fixedimg row h-[84vh] p-0 m-0' >
                <section className='col-md-6 flex p-0 m-0 ' >
                    <article style={{
                        background: 'rgb(46,53,64)',
                        opacity: '0.9'
                    }} className=' w-11/12 sm:w-4/5 p-4 rounded-ss-3xl  ms-auto mt-auto h-4/5 ' >
                        <h4 className='text-slate-50 poppins text-4xl' >Our Projects </h4>
                        <div className=' h-[300px] sm:h-[370px] scrolling mt-5 overflow-y-scroll ' >
                            {
                                project.map((data, index) => (
                                    <button onClick={() => { setSelectedIndex(index); setSelectedContent(data) }} className={`${selectedIndex == index ? 'bg-slate-50 text-slate-700 rounded-t-xl ' : 'text-slate-50'} 
                                    poppins border-b-2 block w-full text-start my-2 border-slate-50 py-3 px-2 duration-500 `} >
                                        {data.name}
                                    </button>
                                ))
                            }

                        </div>

                    </article>

                </section>
                <section className='col-md-6 p-0 m-0 flex ' >
                    <article className=' sm:w-4/5 h-11/12 sm:h-3/5 bg-white sm:mt-auto sm:rounded-se-3xl p-4' >
                        <h4 className='poppins fw-semibold txtclr ' >
                            {selectedContent.name} </h4>
                        <p className='text-slate-700 my-3' >
                            {selectedContent.content} </p>
                        <div>
                            <h5 className='fw-semibold text-slate-700 ' > Loacation : <span className=' fw-normal ' >
                                {selectedContent.location} </span> </h5>
                            <h5 className='fw-semibold text-slate-700 ' > Area circumference : <span className=' fw-normal ' >
                                {selectedContent.area} </span>
                            </h5>

                        </div>
                    </article>

                </section>

            </main>

        </div>
    )
}

export default ProjectsSection