import React from 'react'

const ConstructionIcon = ({ size }) => {
    return (
        <svg width={size ? size : "24"} height={size ? size : "24"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class=""><path d="M13 23h-10l-.002-10.016 8.974-7.989 9.011 7.989.017 10.016h-3v-7h-5v7zm-6-7h-2v3h2v-3zm4 0h-2v3h2v-3zm1-15l11.981 10.632-1.328 1.493-10.672-9.481-10.672 9.481-1.328-1.493 12.019-10.632z" />
        </svg>

    )
}

export default ConstructionIcon