import React from 'react'
import RightArrow from '../../Icons/RightArrow'

const OwnerSide = () => {
    return (
        <div className='py-5 ' >
            <main className='container mx-auto row min-h-[80vh] ' >
                <section className='col-md-6 my-3' >
                    <img src={require('../../Assest/Engineer1.webp')} loading='lazy' alt="Engineer"
                        className=' rounded-xl object-cover h-[80vh]  w-full ' />

                </section>
                <section className='col-md-6 my-3' >
                    <article className='px-4 flex flex-col h-full' >

                        <div className='flex items-center gap-2  ' >
                            <RightArrow />
                            <h6 className='sans uppercase text-md fw-semibold txtclr m-0' >Core Feature </h6>
                        </div>
                        <h3 className='text-3xl my-3 poppins sm:text-5xl ' >
                            Building Quality Homes for a Brighter Tomorrow
                        </h3>
                        <p className='sans text-slate-700 my-2 ' >
                            At MS Builders, we believe that quality matters. When you choose us, you invest in homes that last. Our team ensures every detail is handled with care, providing a safe and 
                            inviting space for your family to cherish for years.
                        </p>
                        <p className='sans text-slate-700 my-2 ' >
                        Your happiness is our priority. We build homes that not only look great but also save you money on repairs and maintenance in the 
                        long run. Quality service ensures a brighter tomorrow for you and your family.
                        </p>
                        {/* <img src={require('../../Assest/construction6.webp')} loading='lazy' alt="Construction image"
                            className='h-[30vh] rounded ms-auto flex mt-auto ' /> */}
                        <video autoPlay muted  controls src={require('../../Assest/msbuildersVideo.mp4')} loading='lazy' alt="Construction image"
                            className='h-[30vh] rounded ms-auto flex mt-auto ' ></video>
                    </article>

                </section>
            </main>
            <marquee behavior="alternate" direction="left">
                <h4 className='text-5xl sm:text-5xl lg:text-9xl outlined-text translate-x-1 ' >Take a Look at Our Work Take a Look at Our Work       </h4>
            </marquee>


        </div>
    )
}

export default OwnerSide