import React from 'react'
import RightArrow from '../../Icons/RightArrow'
import { callNumber } from '../../App'
import FaceBookIcon from '../../Icons/FaceBookIcon'
import InstagramIcon from '../../Icons/InstagramIcon'
import YoutubeIcon from '../../Icons/YoutubeIcon'

const FooterCom = () => {
    return (
        <div id='contact' style={{ backgroundColor: 'rgb(34, 34, 34)' }} className='py-5 min-h-[40vh] ' >
            <main className='container mx-auto row  ' >
                <section className='col-sm-6  col-md-5' >
                    <div className='flex items-center gap-1 ' >
                        <span className='text-slate-50 ' >
                            <RightArrow />
                        </span>
                        <span style={{ color: 'rgb(140,173,41)' }}
                            className='uppercase sans fw-bold' >
                            Let's Talk
                        </span>
                    </div>
                    <h5 className='text-slate-50 text-3xl sm:text-5xl my-3 sans ' >
                        We're just a click away! </h5>
                    <p className='text-slate-400 ' >
                        Need to know more about our services? Feel free to contact us anytime. We’re here to help you build your
                        dream space!
                    </p>
                    <article className='flex flex-wrap gap-3 items-center ' >
                        <a href={`tel:${callNumber}`} className='my-3 text-slate-50 text-xl fw-semibold sans sm:text-2xl text-decoration-none ' >
                            {callNumber}
                        </a>
                        <span className='text-slate-400 my-3' >or </span>
                        <a href={`https://wa.me/${callNumber}`} className='my-3 text-slate-50 text-xl fw-semibold sans sm:text-2xl text-decoration-none ' >
                            Chat Now
                        </a>
                    </article>

                    <article className='py-3 flex flex-wrap gap-3 ' >

                        <a href="https://www.facebook.com/profile.php?id=61556596644719" target='_blank' style={{ backgroundColor: 'rgb(43,43,43)' }}
                            className='rounded-full text-slate-50 w-[45px] h-[45px] flex items-center justify-center ' >
                            <FaceBookIcon />
                        </a>
                        <a href="https://www.instagram.com/msbuilders22?igsh=MWVqd3Z0eng3a3Y3OA==" target='_blank' style={{ backgroundColor: 'rgb(43,43,43)' }}
                            className='rounded-full text-slate-50 w-[45px] h-[45px] flex items-center justify-center ' >
                            <InstagramIcon />
                        </a>
                        <a href="https://youtube.com/@msbuilders2k19?si=J5j_RhAkMn--38kV" target='_blank' style={{ backgroundColor: 'rgb(43,43,43)' }}
                            className='rounded-full text-slate-50 w-[45px] h-[45px] flex items-center justify-center ' >
                            <YoutubeIcon />
                        </a>
                    </article>
                </section>
                <section className='col-sm-3 text-slate-50 sans' >
                    <h5 className='text-slate-50  text-2xl ' >Office Address </h5>
                    <p className='text-slate-400 my-3' >
                        9A, Tamilnadu Grama Bank 1st Floor, <br />
                        Trichy -Karur Main Road, <br />
                        Kadambar Kovil, <br />
                        Kulithalai- 639104
                    </p>

                </section>

                <section className='col-sm-3 text-slate-50 sans' >
                    <h5 className='text-slate-50  text-2xl ' >Our Services </h5>
                    {/* <ul className='text-slate-400 list-outside my-3 ' > */}
                    <li className='my-3 list-none ' > <a href="#home" className='text-slate-400 text-decoration-none  my-3' > Home </a> </li>
                    <li className='my-3 list-none ' > <a href="#about" className='text-slate-400 text-decoration-none  my-3' > About </a> </li>
                    <li className='my-3 list-none ' > <a href="#service" className='text-slate-400 text-decoration-none  my-3' > Service </a> </li>
                    <li className='my-3 list-none ' > <a href="#work" className='text-slate-400 text-decoration-none  my-3' > Work </a> </li>

                    {/* </ul> */}

                </section>
                <hr className='border-slate-300 my-3 ' />
                <h6 className='text-slate-400 text-center my-2 mb-0 ' >Copyright @ Ms Builders 2024. All Rights Reserved. </h6>

            </main>

        </div>
    )
}

export default FooterCom