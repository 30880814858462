import React from 'react'

const VehicleIcon = ({ size }) => {
    return (
        <svg width={size ? size : "24"} height={size ? size : "24"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="">
            <path fill='currentColor' d="M16.997 23.982h-14c-1.656 0-3-1.345-3-3 0-1.649 1.334-2.989 2.98-3h14.02c1.656 0 3 1.345 3 3s-1.344 3-3 3zm0-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-9.423 0c-.553 0-1 .448-1 1s.447 1 1 1c.551 0 1-.448 1-1s-.449-1-1-1zm-4.577 0c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm9.378 0c-.552 0-1.001.448-1.001 1s.449 1 1.001 1c.552 0 1-.448 1-1s-.448-1-1-1zm4.313-3h-15.691c-.621 0-1-.523-1-1v-5c0-.621.523-1 1-1h6v-3c0-.553.447-1 1-1h3.326c.631-.828 3.21-4.214 4.289-5.6.195-.251.489-.386.789-.386.152 0 .304.034.447.105l6.71 3.365c.276.137.442.417.442.715 0 .103.053-.011-.935 3.577.227.615.513 1.407.745 2.081.119.349.187.694.187 1.025 0 1.507-1.535 2.335-2.728 2.066-1.544-.349-3.598-.91-3.598-.91l3.691-3.898.251-3.14-4.616-1.484-1.597 3.963 2.252 8.258c.173.633-.302 1.263-.964 1.263zm-14.691-2h4v-1h-4v1zm0-2h4v-1h-4v1zm11.482-5h-4.482v4h5.527l-1.045-4z" />
            </svg>

    )
}

export default VehicleIcon