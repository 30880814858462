import React, { useState } from 'react'
import RightArrow from '../../Icons/RightArrow'
import PlainingIcon from '../../Icons/PlainingIcon'
import MachineryIcon from '../../Icons/MachineryIcon'
import ConstructionSite from '../../Icons/ConstructionSite'
import BuildingIcon from '../../Icons/BuildingIcon'
import BrickIcon from '../../Icons/BrickIcon'

const HowitWorks = () => {
    let [selectedIndex, setSelectedIndex] = useState(0)
    let workFlow = [
        {
            icon: <PlainingIcon size={50} />,
            name: 'Planning & Design'
        },
        {
            icon: <ConstructionSite size={50} />,
            name: 'Pre Construction'
        },
        {
            icon: <BrickIcon size={50} />,
            name: 'Site Preparation '
        },
        {
            icon: <BuildingIcon size={50} />,
            name: 'Final Fixtures'
        },
    ]
    return (
        <div id='work' className='bg-slate-100 py-5 ' >
            <main className='container mx-auto row  ' >
                <section className='col-sm-6 ' >
                    <div className='flex gap-2 items-center  ' >
                        <RightArrow />
                        <span className=' fw-bold sans uppercase txtclr ' >
                            How it works
                        </span>
                    </div>
                    <h5 className='jakarta text-3xl sm:text-5xl my-3 ' >
                        Building Your Dream <br /> Space
                    </h5>
                </section>
                <section className='col-sm-6 ' >
                    <p className='jakarta text-slate-700 ' >
                        Your dream house is within reach with MS Builders! Share your ideas and details with us, and we’ll
                        take it from there. Our team guides you through each step to ensure everything is perfect.
                        From designing your ideal space to preparing the site and adding finishing touches, we handle it all with care. Relax and let us create a warm, welcoming house for you and your family.

                    </p>
                   
                </section>
                {
                    workFlow.map((obj, index) => (


                        <section onMouseEnter={() => setSelectedIndex(index)}
                            onClick={() => setSelectedIndex(index)}
                            className={`${selectedIndex == index ? 'shadow bg-white' : ''} duration-700 h-[27vh] my-4
                col-sm-3 p-4 flex flex-col items-center txtclr justify-center `} >
                            {obj.icon}
                            <h5 className='my-3 poppins text-slate-800 ' >
                                {obj.name} </h5>
                        </section>
                    ))
                }

            </main>


        </div>
    )
}

export default HowitWorks