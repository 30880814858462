import React from 'react'

const SafetyHelmetIcon = ({ size }) => {
    return (
        <svg width={size ? size : "24"} height={size ? size : "24"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="">
            <path d="M2.178 11h-1.178v-2c.488-.001.784-.278.862-.665.637-2.461 3.164-8.335 10-8.335 6.888 0 9.484 6.135 10.294 8.413.15.421.459.584.844.584l.001 2.003h-1.18c.575.459 1.179 1.36 1.179 3.131 0 1.63-.904 3.686-2.877 4.531-2.153 3.445-5.027 5.338-8.123 5.338-3.096 0-5.97-1.893-8.123-5.338-1.974-.845-2.877-2.901-2.877-4.531 0-1.771.603-2.672 1.178-3.131zm12.022 7.459h-4.4c.004.012.626 1.74 2.2 1.74 1.634 0 2.196-1.728 2.2-1.74zm4.517-7.459h-13.435l-.013.515c0 .668-.682 1.114-1.288.844-.169-.075-.43-.073-.617.049-.917.601-.818 3.864 1.425 4.629.916.313 2.364 3.103 3.93.398.542-.934 2.251-1.039 3.281-.297 1.029-.742 2.739-.637 3.28.297 1.566 2.705 3.014-.085 3.931-.398 2.244-.765 2.341-4.028 1.424-4.629-.215-.14-.487-.106-.616-.049-.606.271-1.289-.176-1.289-.844l-.013-.515zm-9.696.996c-.634 0-1.146.62-1.146 1.385s.512 1.385 1.146 1.385c.632 0 1.146-.62 1.146-1.385s-.514-1.385-1.146-1.385zm7.104 1.385c0 .765-.513 1.385-1.146 1.385-.633 0-1.146-.62-1.146-1.385s.513-1.385 1.146-1.385c.633 0 1.146.62 1.146 1.385zm-5.125-11.338c-.735.075-1.4.244-2 .483v3.474c0 .552-.448 1-1 1s-1-.448-1-1v-2.269c-2.153 1.817-2.985 4.513-3.19 5.269h16.395c-.333-1.006-1.189-3.46-3.205-5.177v2.177c0 .552-.448 1-1 1s-1-.448-1-1v-3.406c-.599-.248-1.264-.431-2-.524v2.93c0 .552-.448 1-1 1s-1-.448-1-1v-2.957z" />
        </svg>
    )
}

export default SafetyHelmetIcon