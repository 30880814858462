import React, { useState } from 'react'
import RightArrow from '../../Icons/RightArrow'

const FaqsHome = () => {
    let [selectedIndex, setSelectedIndex] = useState(0)
    let faqs = [
        {
            qn: 'Why should I choose MS Builders for my project?',
            ans: `We offer quality construction, thoughtful design, and reliable services that ensure your home feels just right for your family and 
            generations to come.`
        },
        {
            qn: 'How long will my project take to finish?',
            ans: `Completion time varies by project size. We’ll ensure smooth progress with clear schedules and regular updates throughout.`
        },
        {
            qn: 'Can you customize designs to fit my lifestyle?',
            ans: `Absolutely! We create unique spaces that reflect your personality, making every part of your home feel special.`
        },
    ]
    return (
        <div className='py-5 ' >
            <main className='container mx-auto row items-center' >
                <section className='col-lg-5  my-3 flex  ' >
                    <img src={require('../../Assest/construction5.webp')} alt="Constructions"
                        loading='lazy' className='rounded h-[70vh] w-full object-cover my-auto' />
                </section>
                <section className='col-lg-7 my-3 flex ' >
                    <article className='my-auto px-3 w-full ' >
                        <div className='flex gap-2 items-center ' >
                            <RightArrow />
                            <span className='txtclr uppercase fw-bold ' >
                                FAQ
                            </span>
                        </div>
                        <h3 className='text-4xl text-slate-700 sm:text-6xl my-3' >
                            Your Questions,<br /> Answered Here


                        </h3>
                        <section className='my-4 w-full  ' >
                            {
                                faqs.map((obj, index) => (
                                    <div>
                                        <div onClick={() => setSelectedIndex(selectedIndex == index ? -1 : index)}
                                            className='border-2 rounded cursor-pointer shadow-sm w-full my-3 p-2 py-3 flex justify-between items-center ' >
                                            <h5 className='sans text-slate-800 m-0 ' >{obj.qn} </h5>
                                            <button className={` text-3xl fw-bold
                                                 ${selectedIndex == index ? '' : ''} `} >
                                                {selectedIndex == index ? '-' : '+'}
                                            </button>
                                        </div>
                                        <p className={` ${selectedIndex == index ? 'h-fit py-3 ' : ' h-0 overflow-hidden '}
                                         text-sm text-slate-700 sans px-3 duration-500 `} >
                                            {obj.ans}
                                        </p>

                                    </div>
                                ))
                            }
                        </section>


                    </article>
                </section>

            </main>

        </div>
    )
}

export default FaqsHome