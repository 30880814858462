import React, { useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const NavBarCom = () => {
    let navigate = useNavigate()
    let [show, setShow] = useState(false)
    let expand = 'lg'
    return (
        <div className=' sticky top-0 bg-white z-10' >
            <Navbar key={expand} expand={expand} className=" border-b-[1px]  ">
                <Container>
                    <Navbar.Brand href="#">
                        <img src={require('../Assest/msbuilders.png')} alt="Pitheta" className=' ' width={100} />
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => setShow(true)} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas

                        // id={`offcanvasNavbar-expand-${expand}`}
                        show={show}
                        onHide={() => setShow(false)}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                <img src={require('../Assest/msbuilders.png')} alt="Pitheta" className=' ' width={100} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className=" flex-grow-1 sans justify-end  pe-3">
                                <div className='lg:w-[40%] flex flex-col flex-sm-row justify-between fw-semibold' >
                                    <Nav.Link onClick={() => setShow(false)} href="#home"> <span className='sans ' > Home </span> </Nav.Link>
                                    <Nav.Link onClick={() => setShow(false)} href="#about"> <span className='sans ' > About </span> </Nav.Link>
                                    <Nav.Link onClick={() => setShow(false)} href="#service"> <span className='sans ' > Service  </span> </Nav.Link>
                                    <Nav.Link onClick={() => setShow(false)} href="#work"> <span className='sans ' > Work  </span> </Nav.Link>
                                    <Nav.Link onClick={() => setShow(false)} href="#contact"> <span className='sans ' > Contact Us  </span> </Nav.Link>


                                </div>
                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavBarCom