import React from 'react'
import NavBarCom from '../Component/NavBarCom'
import MiniNavBar from '../Component/MiniNavBar'
import HomeBanner from '../Component/HomeCom/HomeBanner'
import MissionVision from '../Component/HomeCom/MissionVision'
import ServiceSection from '../Component/HomeCom/ServiceSection'
import OwnerSide from '../Component/HomeCom/OwnerSide'
import ProjectsSection from '../Component/HomeCom/ProjectsSection'
import HowitWorks from '../Component/HomeCom/HowitWorks'
import FaqsHome from '../Component/HomeCom/FaqsHome'
import FooterCom from '../Component/HomeCom/FooterCom'

const HomePage = () => {
  return (
    <div>
      <MiniNavBar />
      <NavBarCom />
      <HomeBanner />
      <MissionVision />
      <ServiceSection />
      <OwnerSide />
      <ProjectsSection />
      <HowitWorks />
      <FaqsHome/>
      <FooterCom/>
    </div>
  )
}

export default HomePage