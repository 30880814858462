import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css'
import HomePage from './Page/HomePage';
import './Css/home.css'
import 'bootstrap/dist/css/bootstrap.min.css';

export const callNumber='+919042523227'
export const mail='msbuilders2k19@gmail.com'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/*' element={<HomePage/>} />
      </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
