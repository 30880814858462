import React, { useState } from 'react'
import RightArrow from '../../Icons/RightArrow'
import HomeIcon from '../../Icons/HomeIcon'
import StrippedConeIcon from '../../Icons/StrippedConeIcon'
import ConstructionSite from '../../Icons/ConstructionSite'
import BrushIcon from '../../Icons/BrushIcon'
import PlainingIcon from '../../Icons/PlainingIcon'
import ConstructionIcon from '../../Icons/ConstructionIcon'
import BuildingIcon from '../../Icons/BuildingIcon'
import SafetyHelmetIcon from '../../Icons/SafetyHelmetIcon'
import VehicleIcon from '../../Icons/VehicleIcon'

const ServiceSection = () => {
    let [selectedIndex, setSelectedIndex] = useState(0)
    let services = [
        {
            name: 'Residential Construction',
            icon: <HomeIcon size={50} />,
            content: `Build your dream home with us, reflecting your personality and style in every detail and design.`,
        },
        {
            name: 'Commercial Construction',
            icon: <ConstructionSite size={50} />,
            content: `Functional spaces designed to welcome customers and support your business success every day.`,
        },
        {
            name: 'Renovations and Remodeling',
            icon: <BrushIcon size={50} />,
            content: `Turn your current space into something new with fresh designs, personalized to your lifestyle.`,
        },
        {
            name: 'Design-Build Services',
            icon: <PlainingIcon size={50} />,
            content: `Work with us for a simple process from start to finish, making everything easier for you.`,
        },
        {
            name: 'Pre-Construction Services',
            icon: <ConstructionIcon size={50} />,
            content: `Plan ahead with us to ensure your project runs smoothly and stays on track.`,
        },
        {
            name: 'Consultation Services',
            icon: <BuildingIcon size={50} />,
            content: `Get expert advice to help you make the best choices for your construction projects.`,
        },
        {
            name: 'Sustainable Building',
            icon: <SafetyHelmetIcon size={50} />,
            content: `Build a home that’s kind to the planet, using eco-friendly materials and energy-saving designs.`,
        },
        {
            name: 'Landscaping and Exterior Work',
            icon: <VehicleIcon size={50} />,
            content: `Enhance your home’s outdoor area, creating a beautiful space for relaxation and play.`,
        },

    ]
    return (
        <div id='service' className='py-5 bg-slate-100 bg-opacity ' >
            <main className='row container mx-auto ' >
                <section className='col-md-4 my-3 h-[30vh] flex p-4 items-center' >
                    <article>
                        <p className=' fw-bold mb-1 txtclr uppercase sans flex items-center gap-2 ' >  <RightArrow /> Explore the services </p>
                        <h4 className='text-3xl text-slate-700 sm:text-5xl poppins' >How We Can Help You? </h4>
                    </article>
                </section>
                {
                    services.map((obj, index) => (
                        <section onClick={() => setSelectedIndex(selectedIndex == index ? -1 : index)}
                            onMouseEnter={() => setSelectedIndex(index)} 
                            className={`duration-500 my-3 col-md-4 flex items-center`} >
                            <article className={`duration-500 ${selectedIndex == index ? 'bg-white  brclr' : ' border-slate-100 '}
                             min-h-[35vh] border-t-4 p-4 txtclr`} >
                                {obj.icon}
                                <p className='text-slate-600 uppercase text-sm my-2 sans  '> Service #{index + 1} </p>
                                <h5 className='fw-bold text-slate-800 my-3 ' > {obj.name} </h5>
                                <p className='text-slate-600 '>
                                    {obj.content}
                                </p>
                            </article>
                        </section>
                    ))
                }

            </main>
        </div>
    )
}

export default ServiceSection