import React from 'react'

const BrickIcon = ({ size }) => {
    return (
        <svg width={size ? size : "24"} height={size ? size : "24"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="">
            <path d="M10 18v5h-10v-5h10zm10 0v5h-9v-5h9zm4-6v5h-7v-5h7zm-17 0v5h-7v-5h7zm9 0v5h-8v-5h8zm-6-6v5h-10v-5h10zm10 0v5h-9v-5h9zm-13-5v4h-7v-4h7zm8 0v4h-7v-4h7z" />
        </svg>
    )
}

export default BrickIcon