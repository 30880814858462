import React from 'react'

const BrushIcon = ({ size }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size ? size : "24"} height={size ? size : "24"} viewBox="0 0 24 24" fill="currentColor" class="">
            <path d="M17.839 7.67c1.095-1.098 2.874-1.871 3.685-3.113.887-1.365.502-3.188-.863-4.082-1.363-.885-3.188-.499-4.086.865-.838 1.288-.791 3.324-1.424 4.781-.812 1.883-3.552 2.123-6.706.488-1.722 3.356-4.065 6.957-6.445 9.884l11.548 7.507c1.496-2.886 4.407-7.348 6.441-9.883-2.827-2.263-3.675-4.925-2.15-6.447zm1.752-5.545c.452.294.582.906.282 1.357-.294.461-.905.584-1.356.29-.458-.299-.585-.903-.287-1.356.292-.458.903-.589 1.361-.291zm-6.664 19.227l-8.25-5.362c.976-1.265 1.753-2.481 2.812-4.104.427-.656.933-.793 1.234-.598 1.104.717-1.507 3.625-.329 4.391 1.169.759 2.889-3.368 4.161-2.539.985.64-.79 2.698.209 3.346.465.303 1.133-.258 1.716-.631 1.006-.645 1.939.006.956 1.516-.981 1.501-1.516 2.315-2.509 3.981z" />
        </svg>
    )
}

export default BrushIcon