import React, { useState } from 'react'

const MissionVision = () => {
    let [open, setOpen] = useState(0)
    let data = [
        {
            heading: 'Our mission',
            contentHeading: 'Turning Spaces into Beautiful Homes Through Quality Construction and Thoughtful Design',
            content: [
                ` We believe every home should reflect your personality and feel uniquely yours. Our skilled team combines craftsmanship and innovative design to create inviting spaces that prioritize quality, comfort, and reliability. Every project is built with care, so your home feels 
                just right for years to come.`,
            ],
            img: '../../Assest/msBuildersConstruction-min.jpg'
        },
        {
            heading: 'Our vision',
            contentHeading: 'Building Strong Foundations That Bring Families Together and Create Lasting Memories.',
            content: [
                ` We aim to create homes where you’ll love to live. Our spaces are designed for families to grow, connect, and celebrate life together. Every corner is designed to be warm and welcoming, ensuring you can enjoy precious moments with loved ones, in a place that feels 
                like home.`,
            ],
            img: '../../Assest/msBuildersConstruction2-min.jpg'
        },

    ]
    return (
        <div id='about' className='py-5' >
            <main className='container mx-auto ' >
                {
                    data.map((obj, index) => (
                        <div>
                            <h5 onClick={() => setOpen(open == index ? -1 : index)}
                                className={`cursor-pointer border-b-[1px] brclr sans text-3xl sm:text-6xl lg:text-8xl `} >
                                {obj.heading} </h5>
                            <section className={`${open == index ? ' openupani py-5 ' : 'h-0 '} duration-500
                 overflow-hidden row container mx-auto items-center `} >
                                <article className='col-lg-6 ' >
                                    <img src={obj.img ? obj.img : require('../../Assest/msBuildersConstruction2-min.jpg')} alt="Construction 2"
                                        className='rounded-xl h-[40vh]  mx-auto' loading='lazy' />
                                </article>
                                <article className='col-lg-6 sans' >
                                    <h4 className='poppins fw-semibold my-2' >
                                        {obj.contentHeading}
                                    </h4>
                                    {obj.content.map((val) => (

                                        <p className='my-2 ' >
                                            {val}
                                        </p>
                                    ))}
                                </article>
                            </section>
                        </div>
                    ))
                }
            </main>

        </div>
    )
}

export default MissionVision